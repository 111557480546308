/**
 File to declare constants related to Optimizely experiments
 */

const PROJECT_ID = '11355970984' // production
const TEST_PROJECT_ID = '17294405044' // stage

/**
 * We have set up proxying for optimizely through our CDN
 * @readonly
 */
const OPTIMIZELY_URL = 'https://optimizely-cdn.ethoslife.com/js'

export const OPTIMIZELY_SCRIPT_SRC = `${OPTIMIZELY_URL}/${PROJECT_ID}.js`
export const OPTIMIZELY_SCRIPT_SRC_DEV = `${OPTIMIZELY_URL}/${TEST_PROJECT_ID}.js`

// Used to trigger Daily Premium pricing on BoF Package Select
// Also used to trigger Quick Scan treatment on PreInterview /q/recommendation
export const DAILY_DOLLARS_COOKIE = {
  NAME: 'ETHOS_DAILY_DOLLARS_TOF',
  EW_VALUE: 'DAILY_EW',
}

// Matching LP copy to ad campaign copy
// https://ethoslife.atlassian.net/browse/GC-2183
// https://app.optimizely.com/v2/projects/15340850549/experiments/24139470024/variations
export const LP_CAMPAIGN_1 = {
  EXPERIMENT_KEY: 'lpCampaign8888464272',
  VARIATIONS: {
    CONTROL: 'original',
    TREATMENT: 'TREATMENT',
  },
  HEADING: 'Instant term life insurance',
}

/**
 * flag for the Final Expense fake preinterview flow
 */

export const FINAL_EXPENSE_FAKE_PREINTERVIEW = {
  EXPERIMENT_KEY: 'gc-4104-fake-fe-flow',
  VARIATIONS: {
    CONTROL: 'off',
    DELAYED_PII: 'delayed_pii',
    GROWTH_WINS: 'growth_wins',
  },
}

/**
 * Experiment to simulate a landing page that looks like the start of the main app
 */

export const ASTA = {
  VARIATIONS: {
    TREATMENT: 'TREATMENT',
  },
  COOKIE_NAME: 'asta', // same as experiment key but want to be explicit
}

export const FINAL_EXPENSE_WIDGET = {
  EXPERIMENT_KEY: 'xp-fe-widget-gc-4077',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
  PAGE_VALUES: {
    control: 'finalExpense-control',
    treatment: 'finalExpense-treatment',
  },
}

export const UPDATED_CP_FE_LP = {
  EXPERIMENT_KEY: 'updated_cp_experience',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment1', // Without Qoute
    TREATMENT_2: 'treatment2', // With sales
  },
  PAGE_VALUES: {
    control: 'finalExpense-control',
    treatment: 'finalExpense-treatment',
  },
}

export const UPDATED_CP_FE_LP_NON_WORKING_HOURS = {
  EXPERIMENT_KEY: 'updated_cp_experience_non_working_hours',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment1', // Without Qoute
  },
  PAGE_VALUES: {
    control: 'finalExpense-control',
    treatment: 'finalExpense-treatment',
  },
}

export const FE_LP_NEW_HEADER = {
  EXPERIMENT_KEY: 'fe_lp_vs_new_header_cc-4007',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

export const FE_INCOMING_CALLS_LP = {
  EXPERIMENT_KEY: 'fe_incoming_calls_lp',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
  },
}

export const FE_ESTIMATE_WIDGET_WITH_PHONE = {
  EXPERIMENT_KEY: 'fe_est_widget_with_phone',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_v3_3_gc-5243/rules/development/edit/wills_v3_3_gc-5243
export const WILLS_V3_4 = {
  EXPERIMENT_KEY: 'wills_v3_4_gc-5331',
  VARIATIONS: {
    CONTROL: 'control',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
    TREATMENT_4: 'treatment_4',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/question_template_preinterview/rules/development
export const QUESTION_TEMPLATE = {
  EXPERIMENT_KEY: 'question_template_preinterview',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
}
/*
 * Wills And Trust pricing experiment Flag
 * https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_pricing_experiment__gc-4671/rules/development/edit/wills_pricing_experiment__gc-4671
 */

export enum WillsAndTrustPricingVariants {
  OFF = 'off',
  TREATMENT_1 = 'treatment_1',
  TREATMENT_2 = 'treatment_2',
}

export const WILLS_AND_TRUST_PRICING_FLAG = {
  EXPERIMENT_KEY: 'wills_pricing_experiment__gc-4671',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

export const WILLS_NEW_HERO_SECTION = {
  EXPERIMENT_KEY: 'gc-5806-wills-new-hero-section',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

export const SINGLE_CTA_EXPERIMENT = {
  EXPERIMENT_KEY: 'single_cta_experiment',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_2: 'treatment_2',
  },
  COOKIE: {
    NAME: 'single_cta',
    VALUE: 'true',
  },
}

export const DIRECT_TO_APP_UX = {
  EXPERIMENT_KEY: 'direct-to-app-ux-gc-4732',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
  },
  COOKIE: {
    NAME: 'direct_to_app',
    VALUE: 'true',
  },
}

export const COBRANDING_ON_MOBILE = {
  EXPERIMENT_KEY: 'gc-5773-cobranding_on_mobile',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
  },
}

export const SINGLE_CTA_PRICE_COMPARISON = {
  EXPERIMENT_KEY: 'single_cta_price_comparison_v2',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_A: 'variant_a',
    VARIANT_B: 'variant_b',
    VARIANT_C: 'variant_c',
    VARIANT_D: 'variant_d',
  },
}

export const WREATHS_FOOTER_UPDATE = {
  EXPERIMENT_KEY: 'wreathsv2_footer_update-gc-6721',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'variant_1',
  },
}

export const HEADLINE_RAPID_TEST = {
  EXPERIMENT_KEY: 'headline-rapid-test-gc-5474',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
    VARIANT_4: 'variant_4',
    VARIANT_5: 'variant_5',
    VARIANT_6: 'variant_6',
  },
}

export const COBRANDING_NEW_PARTNERS = {
  EXPERIMENT_KEY: 'cobranding_for_new_partners-gc-5774',
  cookieName: 'cobranding_partner',
  audiences: ['/life/quick-online-quote/'],
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT_1: 'treatment_1',
  },
}

export const CTA_RAPID_TEST = {
  EXPERIMENT_KEY: 'cta_rapid_gc-5621',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
    VARIANT_4: 'variant_4',
    VARIANT_5: 'variant_5',
    VARIANT_6: 'variant_6',
  },
}

export const BRAND_COLORS = {
  EXPERIMENT_KEY: 'brand_colors_gc-5613',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
  },
}

export const BRAND_COLORS_LANDING_PAGE = {
  EXPERIMENT_KEY: 'brand_colors_gc-5954',
  VARIATIONS: {
    CONTROL: 'off',
    VARIANT_1: 'variant_1',
    VARIANT_2: 'variant_2',
    VARIANT_3: 'variant_3',
  },
}

export const TRUSTAGE_LANDING_PAGE = {
  EXPERIMENT_KEY: 'trustage_landing_page-gc-5024',
  VARIATIONS: {
    CONTROL: 'off',
    TREATMENT: 'treatment',
  },
  MODULE_EXP_KEY: 'trustageLandingPage-off',
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_v3_5_gc-5528/rules/development/edit/wills_v3_5_gc-5528
export const WILLS_V3_5 = {
  EXPERIMENT_KEY: 'wills_v3_5_gc-5528',
  VARIATIONS: {
    CONTROL_5: 'control_5',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}

// https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills_v3_6_gc-6321/rules/test/edit/wills_v3_6_gc-6321
export const WILLS_V3_6 = {
  EXPERIMENT_KEY: 'wills_v3_6_gc-6321',
  VARIATIONS: {
    CONTROL: 'control',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
  },
}

/**
 * WILLS_FAKE_DISCOUNT
 * @see {https://app.optimizely.com/v2/projects/15340850549/flags/manage/wills___trust_fake_discount_gc-6613/rules/development/edit/wills___trust_fake_discount_gc-6613}
 */
export const WILLS_FAKE_DISCOUNT = {
  EXPERIMENT_KEY: 'wills___trust_fake_discount_gc-6613',
  VARIATIONS: {
    OFF: 'off',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
    TREATMENT_3: 'treatment_3',
  },
}
